<template>
    <div class="devTools">
        <language-selector style="display:float"></language-selector>
        <h1><slot name="PageTitle">Titre de la page</slot></h1>
        <div class="intro"><slot name="intro"></slot></div>
        <div class="game-area"><slot></slot></div>
    </div>
</template>

<script>
import LanguageSelector from '../LanguageSelector.vue'
    
export default{
  components: { LanguageSelector },}
</script>
<style scoped>
    div.devTools{
        width:100%;
        height:100%;
        border: solid 1px orangered;
        padding: 5px;
    }
    div.devTools > h1{ color:orangered; font-size: smaller;}
    div.devTools > .intro{ background-color: rgb(229, 197, 186); color: orangered;}
</style>