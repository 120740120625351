<template>
    <dev-tool-layout>
        <h1>{{ paramId  }}</h1>
        <template #PageTitle>Test de composants, portions de code...</template>
        <template #Intro>Cette page sert à tester des portions de code, des composants. Elle peut ne pas être fonctionnelle.</template>
        <b>Test du composant de saisie des notes de ballet</b>
        <ballet-note-editor
            :execution="note.execution"
            :choreo="note.choreo"
            @change="note_change"
            @cancel="note_cancel"
        ></ballet-note-editor>
        <br/>
        <b>Test du composant de saisie des notes de routine</b>
        <routine-note-editor 
            :execution="noteR.execution"
            :content="noteR.content"
            @change="noteR_change"
            @cancel="noteR_cancel"
        >
        </routine-note-editor>
        <br/>
        <b>Test du composant de saisie des notes de routine</b>
        <compulsory-note-editor
            :value="noteC.entryValue"
            :average="noteC.average"
            :compulsory_code="compulsory"
            @change="noteC_change"
            @cancel="noteC_cancel"
        >
        </compulsory-note-editor>
       <div style="background-color:lightgreen" v-html="results"></div>
        <qrcode value="/devtools/testing/50"></qrcode>
        <div><b-button @click="readQR = !readQR">{{ readQR ?  "Masquer la caméra" : "Liste un QR Code" }}</b-button>Last result : {{ qr_result }}</div>
       <qrcode-reader :camera="camera" @decode="onDecode" @init="onInit" v-if="readQR"></qrcode-reader>
</dev-tool-layout>
</template>
        
<script>
import DevToolLayout from '../../components/PageTemplates/DevToolLayout.vue';
import BalletNoteEditor from '../../components/ScoringGrid/BalletNoteEditor.vue';
import CompulsoryNoteEditor from '../../components/ScoringGrid/CompulsoryNoteEditor.vue';
import RoutineNoteEditor from '../../components/ScoringGrid/RoutineNoteEditor.vue';
import qrcode from "qrcode.vue";
import { QrcodeReader } from "vue-qrcode-reader";

export default {
  components: { DevToolLayout, BalletNoteEditor, RoutineNoteEditor, CompulsoryNoteEditor, qrcode, QrcodeReader },
  data(){ 
        return {
            note: {
                execution: 50,
                choreo:50
            },
            noteR: {
                execution: 50,
                content: 50
            },
            noteC: {
                entryValue: 50,
                average: false,
            },
            compulsory: 'DI-02',
            paramId: null,
            qr_result: '',
            readQR: false,
        }
    },
    methods:{
        onDecode(result){
            this.qr_result = result;
        },
        async onInit(promise){
            try{
                await promise
            }catch(error){
                this.error = 'eeror';
            }
        },
        note_change(notes){
            console.log("note_change", notes);
            this.note = notes;
            //{ grossExecution: numEntryE, grossChoreo: numEntryC, execution: exec, choreo: choreo, grossTotal: grossTotal, total: total, penalties: this.penaltiesSet, cartouche: this.cartouche })
        },
        note_cancel(){
            console.log("note_cancel");
        },
        noteR_change(notes){
            console.log("noteR_change", notes);
            this.noteR = notes;
            //{ grossExecution: numEntryE, grossChoreo: numEntryC, execution: exec, choreo: choreo, grossTotal: grossTotal, total: total, penalties: this.penaltiesSet, cartouche: this.cartouche })
        },
        noteR_cancel(){
            console.log("noteR_cancel");
        },
        noteC_change(note){
            console.log("noteC_change", note);
            this.noteC = note;
        },
        noteC_cancel(){
            console.log('noteC_cancel');
        }
    },
    computed:{
        results(){
            return "<b>BALLET</b> =><br/>"  + JSON.stringify(this.note) + "<br/><b>ROUTINE</b> =><br/>" + JSON.stringify(this.noteR) + "<br/><b>FIGURE</b> =><br/>" + JSON.stringify(this.noteC);
        }
    },
    mounted(){
        this.$hideLoader();
        //this.paramId = this.$route.params.id;
//        console.log('detail', this.detail);
/*        const BalletPenaltiesCard = {
            missing_start_or_end: { cost: -5}

        }*/
/*        var card = { a: {cost: 5, appli: 'relative'}, b: {cost: 5, appli: 'relative'}, c:{ cost: 0, appli: 'absolute'}, d:{ cost: 3, appli:'per-item'}};
        var set = { c: false, a: true, d: 1};
        var tot = 0;
        var gross_score = 67;
        var score = gross_score;
        for(const property in card){
            var found = set[property];
            if(found){
                var penaltyRule = card[property];
                if(penaltyRule.appli == 'absolute')
                {
                    tot = '*ZERO*';
                    score = 0;
                    break;
                }
                if(penaltyRule.appli == 'per-item'){
                    tot += (penaltyRule.cost * found);
                    score -= (penaltyRule.cost * found);
                }
                else
                {
                    tot += penaltyRule.cost;
                    score -= penaltyRule.cost;
                }
                console.log('property/found', property, found);
            }
        }
        console.log('total', { score: gross_score, penalties: tot, end_score: score} );*/
    }
}
</script>
